import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import CGPBoardComponent from "../components/board/board.component"
import CGPBoxComponent from "../components/board/box.component"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import CGPTransitionRowFixedComponent from "../components/transitions/components/transition-row-fixed.component"
import CGPTransitionRowSpawnComponent from "../components/transitions/components/transition-row-spawn.component"
import CGPTransitionRowComponent from "../components/transitions/transition-row.component"
import { getRoute, ROUTES_ID } from "../constants/routes.contants"
import "../styles/conseils.css"

const Conseils = () => {
  const route = getRoute(ROUTES_ID.CONSEILS)

  const [qualitySelect, setqualitySelect] = useState(-1)
  const isQualitySelected = qualityNumber => qualityNumber === qualitySelect

  return (
    <>
      <Seo title={route.screenTitle} />
      <Layout>
        <h2 className="background-main-green-color">
          Ma vision du conseil en investissement
        </h2>

        <div className="vision-row">
          <div className="background-main-grey-color conseils-container flex-center">
            <p className="text-ligth justify">
              Ce sont plusieurs constats qui m’ont poussé à créer ACI en 2018.
              Pour commencer, nous sommes tous confrontés à la gestion d’un
              budget. Pourtant cette matière ne fait pas partie du système
              scolaire. Ma volonté en premier lieu est donc d’expliquer, avec
              pédagogie, les rouages de l’investissement et les produits
              utilisés. Cela permet de prendre des décisions en adéquation avec
              ses projets et son profil d’investisseur.
            </p>
          </div>

          <div className="conseils-container flex-center">
            <p className="text-ligth justify">
              Je vous accompagne ensuite pour la mise en place, de ces
              solutions. S’il est facile de comprendre la complexité d’une
              opération immobilière, c’est moins flagrant pour les placements
              financiers. Il n’est pourtant pas facile de de s’y retrouver dans
              la constellation de produits proposés ou de société de gestion qui
              proposent leur gestion ! Rien de tel qu’un expert pour marquer les
              différences et surtout les atouts de chaque solution.
            </p>
          </div>

          <div className="background-main-grey-color conseils-container flex-center">
            <p className="text-ligth justify">
              Et l’étape la plus importante d’un investissement réussi, pourtant
              souvent négligée, c'est le suivi ! Le meilleur des investissements
              doit être adapté dans le temps, car l’environnement mais également
              vos projets vont évoluer. Et si de petites adaptations régulières
              semblent anodines, elles empêchent finalement de s’écarter de la
              trajectoire qu’on se fixe !
            </p>
          </div>
        </div>

        <CGPTransitionRowComponent className="conseils-produits-row">
          <CGPTransitionRowSpawnComponent
            className="row flex-center background-main-green-color text dynamic full-width"
            disable={!isQualitySelected(-1)}
          >
            <h2>Les produits d'investissement →</h2>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="column flex-center background-main-green-color text dynamic left-side large"
            disable={!isQualitySelected(0)}
          >
            <h4 className="main-color">
              Assurance Vie et Plan d’Epargne Retraite
            </h4>
            <p className="guillemets justify">
              Deux solutions qui répondent à des projets distincts mais qui
              utilisent les mêmes supports : des fonds d’investissements côtés.
              Ce sont des produits très utilisés, mais parfois pas de la
              meilleure des manières. Ce sont en tous cas des outils qui font
              souvent partie de mes préconisations, notamment pour la
              flexibilité qu’offre un contrat d’assurance vie et ses avantages
              tant sur la fiscalité que sur la transmission. Je peux vous
              proposer ces produits grâce à mon statut de mandataire
              d’intermédiaire en assurance.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="column flex-center background-main-green-color text dynamic left-side large"
            disable={!isQualitySelected(1)}
          >
            <h4 className="main-color">Immobilier locatif</h4>
            <p className="guillemets justify">
              Que ce soit en loi Pinel, en LMNP, en nue-propriété ou via une
              SCPI, l’immobilier locatif peut prendre bien des formes. Je peux
              vous accompagner sur vos investissements en immobilier direct dans
              le neuf, ou vous conseiller sur une opération dans l’ancien. On
              utilise souvent le levier du financement qui permet de se
              focaliser sur l’effort mensuel d’épargne plutôt qu’une épargne
              déjà existante. Mon statut de mandataire d’intermédiaire en
              opérations de banque et en service de paiement ainsi que celui
              d’agent commercial de Stellium Immobilier m’autorise à travailler
              sur la globalité de ces opérations. <br />
              Côté SCPI, les possibilités sont encore plus vastes. Cette
              solution est de plus en plus plébiscitée que ce soit pour des
              revenus complémentaires, en préparation de la retraite ou pour
              investir dans la pierre avec plus de flexibilité que l’immobilier
              direct. Cette fois-ci, c’est mon statut de conseiller en
              investissements financiers qui est nécessaire.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="column flex-center background-main-green-color text dynamic left-side large"
            disable={!isQualitySelected(2)}
          >
            <h4 className="main-color">Capital Investissement</h4>
            <p className="guillemets justify">
              De nouveau via mon statut de CIF, je peux vous proposer cette
              solution que j’affectionne particulièrement : elle permet
              d’investir dans l’économie réelle (c’est bien plus que de
              l’épargne !), de bénéficier du levier fiscal et de diversifier son
              patrimoine sur des placements non côtés. En contrepartie,
              l’investissement doit être particulièrement maitrisé pour mitiger
              son risque dans le temps.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowSpawnComponent
            className="column flex-center background-main-green-color text dynamic left-side large"
            disable={!isQualitySelected(3)}
          >
            <h4 className="main-color">Et bien d’autres !</h4>
            <p className="guillemets justify">
              Girardin industriel, PEA, épargne salariale, … autant de solutions
              certes moins courantes mais que je saurais vous proposer s’ils
              correspondent mieux à votre situation.
            </p>
          </CGPTransitionRowSpawnComponent>

          <CGPTransitionRowFixedComponent className="background-gren-color quality-board">
            <CGPBoardComponent>
              <CGPBoxComponent
                onEnter={() => setqualitySelect(0)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage
                  src="../images/AV_et_PER.png"
                  alt="Assurance Vie et Plan d’Epargne Retraite"
                />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(1)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage
                  src="../images/Immo_loc.png"
                  alt="Immobilier locatif"
                />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(2)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage
                  src="../images/Cap_invest.png"
                  alt="Capital Investissement"
                />
              </CGPBoxComponent>

              <CGPBoxComponent
                onEnter={() => setqualitySelect(3)}
                onLeave={() => setqualitySelect(-1)}
              >
                <StaticImage
                  src="../images/Et_bien_d_autres.png"
                  alt="Et bien d’autres !"
                />
              </CGPBoxComponent>
            </CGPBoardComponent>
          </CGPTransitionRowFixedComponent>
        </CGPTransitionRowComponent>

        <h2 className="background-main-grey-color main-color">
          Les services facturés
        </h2>

        <div className="conseils-service">
          <div className="conseils-container conseils-service-container flex-center">
            <p className="text-ligth justify">
              Les plus courants sont le bilan successoral et la protection de la
              famille. Une analyse plus poussée est alors nécessaire pour
              optimiser chacun de ces sujets. Parfois cela fait partie
              intégrante du bilan patrimonial. Parfois il faut aller un peu plus
              loin et je vous propose alors une analyse plus poussée. Chaque
              situation est particulière.
            </p>
          </div>

          <div className="conseils-container conseils-service-container background-main-green-color flex-center">
            <p className="text-ligth justify">
              La déclaration fiscale peu également nécessiter de l’aide. Si je
              n’ai pas la compétence juridique appropriée qui me permettrait de
              réaliser la déclaration pour vous, je peux néanmoins vous aider à
              la réaliser. Si vous avez réalisé des investissements avec moi qui
              nécessitent d’être déclarés fiscalement, cet accompagnement n’est
              pas facturé.
            </p>
          </div>

          <div className="conseils-container conseils-service-container flex-center">
            <p className="text-ligth justify">
              Toute une série d’autres services que je propose habituellement
              lors de la mise en place peuvent vous être proposés sur des
              investissements que vous auriez réalisé dans le passé.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Conseils
